import * as React from 'react';
import Header from './header';
import Footer from './footer';
import SectionStart from './section-start';
import { PageProps } from 'gatsby';

type LayoutProps = {
  children: React.ReactNode;
} & PageProps;

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  const isHomePage = location ? location.pathname === '/' : false;

  return (
    <main>
      <Header />
      {isHomePage ? (
        <>
          <SectionStart />
          <section className="bg-repeat stp-29 hero_bg_gradient overflow-hidden relative z-10">
            {children}
          </section>
        </>
      ) : (
        <>{children}</>
      )}
      <Footer />
    </main>
  );
};

export default Layout;
