import { Link } from 'gatsby';
import * as React from 'react';

const MainMenu = () => {
  return (
    <nav className="max-lg:hidden">
      <ul className="flex justify-center items-center gap-3">
        <li className="">
          <Link
            to="/"
            className="menu hover:header_menu_shadow duration-700 px-2 py-3 rounded-lg"
          >
            Ana Sayfa
          </Link>
        </li>
        <li>
          <div className="group cursor-pointer relative">
            <div className="flex justify-center items-center gap-1 relative px-2 py-3 rounded-lg hover:header_menu_shadow subMenuTitle">
              Kullanıcı İşlemleri
              <i className="ph ph-caret-down pt-0.5 block group-hover:rotate-180 duration-700"></i>
            </div>
            <ul className="absolute top-12 left-0 pointer-events-none group-hover:eventunset flex justify-start items-start flex-col w-[200px] py-6 gap-3 opacity-0 invisible group-hover:visible group-hover:opacity-100 group-hover:z-50 bg-s1 text-white/80 rounded-lg group-hover:translate-y-0 group-hover:scale-100 translate-y-8 scale-75 duration-500">
              <li>
                <Link
                  to="/football-field"
                  className="px-6 hover:ml-2 duration-500 hover:text-s2 subMenuItem"
                >
                  Saha Arama
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="px-6 hover:ml-2 duration-500 hover:text-s2 subMenuItem"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="px-6 hover:ml-2 duration-500 hover:text-s2 subMenuItem"
                >
                  Iletişim
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="group cursor-pointer relative">
            <div className="flex justify-center items-center gap-1 relative px-2 py-3 rounded-lg hover:header_menu_shadow subMenuTitle">
              Bilgilendirme
              <i className="ph ph-caret-down pt-0.5 block group-hover:rotate-180 duration-700"></i>
            </div>
            <ul className="absolute top-12 left-0 pointer-events-none group-hover:eventunset flex justify-start items-start flex-col w-[250px] py-6 gap-3 opacity-0 invisible group-hover:visible group-hover:opacity-100 group-hover:z-50 bg-s1 text-white/80 rounded-lg group-hover:translate-y-0 group-hover:scale-100 translate-y-8 scale-75 duration-500">
              <li>
                <Link
                  to="/sss"
                  className="px-6 hover:ml-2 duration-500 hover:text-s2 subMenuItem"
                >
                  Hakkımızda
                </Link>
              </li>
              <li>
                <Link
                  to="/sss"
                  className="px-6 hover:ml-2 duration-500 hover:text-s2 subMenuItem"
                >
                  Sıkça Sorulan Sorular (SSS)
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
