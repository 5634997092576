import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const SectionStart = () => {
  return (
    <section className="bg-repeat stp-30 hero_bg_gradient overflow-hidden">
      <StaticImage
        src="../images/hero_bg_element1.png"
        alt="image"
        className="!absolute top-0 left-0 xxxl:left-36 max-lg:w-[300px] max-xxl:w-[500px] max-md:hidden"
      />
      <StaticImage
        src="../images/hero_bg_element2.png"
        alt="image"
        className="!absolute top-0 right-0 max-xxl:w-[300px] max-sm:hidden"
      />
      <div className="absolute -left-[200px] -bottom-1/2 bg-white blur-[200px] rounded-[1176px] max-w-full lg:w-[1176px] h-[1176px] overflow-hidden"></div>
      <div className="xxl:ml-[calc((100%-1296px)/2)] lg:max-xxl:py-10 max-xxl:container relative z-20 max-lg:pt-15 text-s1 grid grid-cols-12">
        <StaticImage
          src="../images/hero_bg_element3.png"
          alt="image"
          className="!absolute top-1/3 left-1/3 max-sm:hidden"
        />
        <div className="col-span-12 lg:col-span-5 flex flex-col justify-center gap-2">
          <p className="uppercase text-base lg:text-xl font-semibold animate__animated animate__fadeInUp">
            Futbol Tutkunlarına Özel: Sahanı Hemen Rezerve Et!
          </p>
          <div className="display-2">
            Kolay ve
            <br />
            <div className="text-s3 inline-flex">Hızlı</div>
            <br />
            Rezervasyon
          </div>
          <p className="max-w-[550px]">
            Favori halı sahalarınızı anında bulun ve rezervasyonunuzu kolayca
            yapın. Artık maç planlamak çok daha basit ve zahmetsiz!
          </p>
          <div className="flex justify-start items-center gap-4 pt-6 lg:pt-8 pb-15">
            <a
              href="#"
              className="font-medium bg-s2 py-2 lg:py-3 px-4 lg:px-6 rounded-full text-mainTextColor"
            >
              Hemen Rezervasyon
            </a>
            <a href="#" className="underline font-medium">
              Daha Fazla Bilgi Al
            </a>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6 lg:col-start-7">
          <StaticImage src="../images/hero_illus.png" alt="image" />
        </div>
      </div>
    </section>
  );
};

export default SectionStart;
