import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-mainTextColor text-white/60 relative">
      <div className="container grid grid-cols-12 stp-30 sbp-30 gap-6 max-xxl:pr-4">
        <div className="col-span-12 min-[450px]:col-span-6 lg:col-span-3 flex flex-col gap-6 lg:gap-8">
          <a href="/">
            <StaticImage
              src="../images/logo_white.png"
              alt="Maç kaç kaç logo"
              width={120}
            />
          </a>
          <p className="">
            Futbol tutkunları için en hızlı ve güvenli saha rezervasyon
            platformu.
          </p>
          <ul className="flex justify-start items-center gap-2">
            <li className="  ">
              <a className="bg-s1/50 rounded-full w-[40px] h-[40px] hover:bg-s1 duration-500 hover:-translate-y-1 text-white flex justify-center items-center">
                <i className="ph ph-facebook-logo leading-[0] text-2xl"></i>
              </a>
            </li>
            <li className="  ">
              <a className="bg-s1/50 rounded-full w-[40px] h-[40px] hover:bg-s1 duration-500 hover:-translate-y-1 text-white flex justify-center items-center">
                <i className="ph ph-reddit-logo leading-[0] text-2xl"></i>
              </a>
            </li>
            <li className="  ">
              <a className="bg-s1/50 rounded-full w-[40px] h-[40px] hover:bg-s1 duration-500 hover:-translate-y-1 text-white flex justify-center items-center">
                <i className="ph ph-youtube-logo leading-[0] text-2xl"></i>
              </a>
            </li>
            <li className="  ">
              <a className="bg-s1/50 rounded-full w-[40px] h-[40px] hover:bg-s1 duration-500 hover:-translate-y-1 text-white flex justify-center items-center">
                <i className="ph ph-pinterest-logo leading-[0] text-2xl"></i>
              </a>
            </li>
          </ul>
        </div>

        <div className="xl:pl-20 col-span-12 min-[400px]:col-span-6 lg:col-span-3">
          <h4 className="heading-4 mb-6 pb-2 relative text-white after:absolute after:w-[20%] after:h-[2px] after:bottom-0 after:left-0 after:bg-p1 hover:after:w-[40%] after:duration-500">
            Sayfalar
          </h4>
          <ul className="flex flex-col gap-4 md:gap-5">
            <li>
              <a
                href="#"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Anasayfa
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Hakkımızda
              </a>
            </li>
            <li>
              <a
                href="/sss"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Sıkça Sorulan Sorular (SSS)
              </a>
            </li>
            <li>
              <a
                href="/blog"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                İletişim
              </a>
            </li>
          </ul>
        </div>
        <div className="xl:pl-20 col-span-12 min-[400px]:col-span-6 lg:col-span-3">
          <h4 className="heading-4 mb-6 pb-2 relative text-white after:absolute after:w-[20%] after:h-[2px] after:bottom-0 after:left-0 after:bg-p1 hover:after:w-[40%] after:duration-500">
            Özellikler
          </h4>
          <ul className="flex flex-col gap-4 md:gap-5">
            <li>
              <a
                href="/football-field"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Gelişmiş Saha Arama
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Kolay Saha Yönetimi
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Detaylı Raporlama ve Analiz
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Hızlı Rezervasyon
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                Erken Rezervasyon Avantajları
              </a>
            </li>
          </ul>
        </div>

        <div className="col-span-12 min-[450px]:col-span-6 lg:col-span-3 xl:pl-20">
          <h4 className="heading-4 mb-4 md:mb-6 pb-2 relative after:absolute after:w-[20%] after:h-[2px] after:bottom-0 after:left-0 after:bg-p1 hover:after:w-[40%] after:duration-500 text-white">
            İletişim
          </h4>
          <ul className="flex flex-col gap-4 md:gap-3">
            <li>
              <a
                href="mailto:bilgi@mackackac.com"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                <span className="text-2xl pt-2">
                  <i className="ph ph-envelope-simple-open"></i>
                </span>
                bilgi@mackackac.com
              </a>
            </li>
            <li>
              <a
                href="tel:+1234579"
                className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500"
              >
                <span className="text-2xl pt-2">
                  <i className="ph ph-phone-call"></i>
                </span>
                İletişime geç!
              </a>
            </li>
            <li>
              <p className="flex justify-start items-center gap-2 hover:text-white hover:translate-x-2 duration-500">
                <span className="text-2xl pt-2">
                  <i className="ph ph-paper-plane-tilt"></i>
                </span>
                Ankara
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-t border-white/10">
        <div className="container py-6 flex justify-between items-center max-md:flex-col gap-6">
          <p className="max-sm:text-center">
            Maç Kaç Kaç © Copyright 2024. All Rights Reserved.
          </p>
          <div className="flex justify-end items-center">
            <a
              href="/privacy"
              className="border-r-2 border-white/60 pr-3 hover:text-white duration-500 leading-none"
            >
              Gizlilik Politikası
            </a>
            <a
              href="/terms"
              className="pl-3 hover:text-white duration-500 leading-none"
            >
              Kullanım Şartları
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
