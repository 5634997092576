import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { ArrowUp, ArrowUpRight, PhoneCall } from '@phosphor-icons/react';
import MainMenu from './menu/main-menu';
import MobileMenu from './menu/mobile-menu';
import { Link } from 'gatsby';

const Header = () => {
  return (
    <>
      <header className="">
        <div className="top-0 left-0 right-0 z-50 header headerAbsolute 2">
          <div className="flex justify-between items-center container text-s1 py-6">
            <div className="pb-1 flex justify-start items-center gap-3">
              <button className="lg:hidden text-3xl mobileMenuOpenButton">
                <i className="ph ph-list"></i>
              </button>
              <a href="/">
                <StaticImage
                  src="../images/logo.png"
                  alt="maç kaç kaç logo"
                  width={120}
                />
              </a>
            </div>

            <MainMenu />

            <div className="flex justify-end items-center gap-2 sm:gap-6 xl:gap-10 font-medium max-sm:hidden">
              <div className="flex justify-between items-center gap-1">
                <span className="bg-s1 rounded-full p-2 md:p-3 text-lg lg:text-2xl !leading-none">
                  <PhoneCall size={24} className="text-s2" />
                </span>
                <a href="#" className="max-xl:hidden">
                  test
                </a>
              </div>
              <Link
                to="/login"
                className="flex justify-center max-sm:text-sm items-center gap-3 py-2 md:py-3 px-3 md:px-6 rounded-full bg-s2 border border-mainTextColor text-mainTextColor group font-medium"
              >
                Hadi Başlayalım!
                <ArrowUpRight
                  size={24}
                  className="text-mainTextColor group-hover:rotate-[45deg] duration-500 text-base lg:text-2xl !leading-[0]"
                />
              </Link>
            </div>
          </div>
        </div>

        <MobileMenu />
      </header>
      <button
        className="fixed text-white text-xl sm:text-2xl rounded-full bg-p1 hover:bg-s2 border border-p1 hover:border-white right-6 md:right-10 p-2 sm:p-3 z-40 jumping1 duration-700 scrollButton scrollButtonHide !leading-[0]"
        aria-label="bottom to top button"
      >
        <span className="block">
          <ArrowUp />
        </span>
      </button>
    </>
  );
};

export default Header;
