import './src/styles/global.css';
import './src/styles/style.css';
import 'animate.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import Layout from './src/components/layout';
import RootElement from './src/components/root-element';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

export const onClientEntry = () => {
  const script = document.createElement('script');
  script.src = '//code.jivosite.com/widget/H6GubjdGTB';
  script.async = true;
  document.head.appendChild(script);
};

const queryClient = new QueryClient();

export const wrapPageElement = ({ element, props }) => {
  const pathsWithoutLayout = [
    '/login/',
    '/register/',
    '/forgotPassword/',
    '/repassword/',
  ];
  const is404 = props.pageContext?.is404;

  const shouldLoadLayout =
    !is404 && !pathsWithoutLayout.includes(props.location.pathname);

  if (!shouldLoadLayout) {
    return (
      <QueryClientProvider client={queryClient}>
        <RootElement>
          <ToastContainer />
          {element}
        </RootElement>
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RootElement>
        <ToastContainer />
        <Layout {...props}>{element}</Layout>
      </RootElement>
    </QueryClientProvider>
  );
};
