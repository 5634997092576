import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const MobileMenu = () => {
  return (
    <nav className="">
      <div className="fixed top-0 left-0 bg-s1/80 h-full w-full lg:hidden duration-700 z-[998] mobileMenuBg mobileMenuBgClose"></div>

      <div className="flex justify-start flex-col items-start gap-8 pb-10 lg:gap-20 fixed lg:hidden top-0 left-0 w-3/4 min-[500px]:w-1/2 h-full bg-s2 overflow-y-auto duration-700 z-[999] mobileMenu mobileMenuClose">
        <div className="flex justify-between items-center w-full p-4 sm:p-8">
          <Link to="/">
            <StaticImage
              src="../../images/logo.png"
              alt="maç kaç kaç logo"
              width={120}
            />
          </Link>
          <i className="ri-close-line !text-3xl cursor-pointer mobileMenuCloseButton">
            <i className="ph ph-x"></i>
          </i>
        </div>

        <ul className="text-lg sm:text-xl flex gap-6 lg:gap-10 items-start flex-col pl-8">
          <li>
            <Link to="/" className="menu-hover hover:after:border-p1">
              Ana Sayfa
            </Link>
          </li>
          <li>
            <div className="flex flex-col justify-start items-start group subMenuToggle">
              <div className="flex justify-start items-center cursor-pointer">
                <span>Kullanıcı İşlemleri</span>
                <i className="!text-xl pl-1 pt-1 duration-500">
                  <i className="ph ph-caret-right"></i>
                </i>
              </div>

              <ul className="pl-4 flex justify-start items-start flex-col gap-2 overflow-hidden duration-700 subMenuClose subMenu">
                <li>
                  <Link to="/football-field" className="text-base">
                    <span>-</span> Saha Arama
                  </Link>
                </li>
                <li>
                  <a href="#" className="text-base">
                    <span>-</span> Blog
                  </a>
                </li>
                <li>
                  <a href="#" className="text-base">
                    <span>-</span> İletişim
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="flex flex-col justify-start items-start group subMenuToggle">
              <div className="flex justify-start items-center cursor-pointer">
                <span>Bilgilendirme</span>
                <i className="!text-xl pl-1 pt-1 duration-500">
                  <i className="ph ph-caret-right"></i>
                </i>
              </div>
              <div className="">
                <ul className="pl-4 flex justify-start items-start flex-col gap-2 pt-2 overflow-hidden duration-700 subMenuClose subMenu">
                  <li>
                    <a href="#" className="text-base">
                      <span>-</span> Hakkımızda
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-base">
                      <span>-</span> Sıkça Sorulan Sorular
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default MobileMenu;
